import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import './EditRequestPage.css'; // Import the CSS file
import { Link } from 'react-router-dom';

const EditRequestPage = () => {
  const { id } = useParams(); // Extract id from URL
  const [request, setRequest] = useState({
    id: '',
    createdDate: '',
    name: '',
    subject: '',
    detail: '',
    description: '',
    isCompleted: false,
    branch: '',
  });
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');
  const [successMessage, setSuccessMessage] = useState('');

  useEffect(() => {
    const fetchRequest = async () => {
      try {
        const response = await axios.get(`https://api.amanosdoner.com/request-list-id/${id}`);
        setRequest(response.data);
        setLoading(false);
      } catch (error) {
        setError('Veri alınamadı');
        setLoading(false);
      }
    };

    fetchRequest();
  }, [id]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setRequest((prevRequest) => ({
      ...prevRequest,
      [name]: value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError('');
    setSuccessMessage('');

    // Validate form fields
    if (!request.description.trim()) {
      setError('Açıklama boş olamaz');
      return;
    }

    try {
      await axios.put(`https://api.amanosdoner.com/update/${id}`, request);
      setSuccessMessage('Talep başarıyla güncellendi');
      setTimeout(() => {
        // Redirect to home page or any other page after successful update
        // Example: history.push('/');
      }, 2000);
    } catch (error) {
      setError('Güncelleme başarısız');
      console.error('Güncelleme hatası:', error);
    }
  };

  return (
    <div className="edit-page-container">
<h1 class="title" >Talep Düzenle</h1>   
   <Link to="/">  <button className="back-to-home-button">Ana Sayfa</button> </Link>  <br /> <br />  <br />
     
      {loading ? (
        <p>Yükleniyor...</p>
      ) : (
        <form onSubmit={handleSubmit} className="edit-form">
          {error && <p style={{ color: 'red' }}>{error}</p>}
          {successMessage && <p style={{ color: 'green' }}>{successMessage}</p>}
          <div>
            <label htmlFor="id">No</label>
            <input
              type="text"
              id="id"
              name="id"
              value={request.id}
              onChange={handleChange}
              disabled
            />
          </div>
          <div>
            <label htmlFor="branch">Şube</label>
            <input
              type="text"
              id="branch"
              name="branch"
              value={request.branch}
              onChange={handleChange}
            />
          </div>
          <div>
            <label htmlFor="name">İsim</label>
            <input
              type="text"
              id="name"
              name="name"
              value={request.name}
              onChange={handleChange}
            />
          </div>
          <div>
            <label htmlFor="subject">Konu</label>
            <input
              type="text"
              id="subject"
              name="subject"
              value={request.subject}
              onChange={handleChange}
            />
          </div>
          <div>
            <label htmlFor="detail">Detay</label>
            <input
              type="text"
              id="detail"
              name="detail"
              value={request.detail}
              onChange={handleChange}
            />
          </div>
          <div>
            <label htmlFor="createdDate">Oluşturulma Tarihi</label>
            <input
              type="text"
              id="createdDate"
              name="createdDate"
              value={request.createdDate}
              onChange={handleChange}
              disabled
            />
          </div>
          <div>
            <label htmlFor="description">Açıklama</label>
            <input
              type="text"
              id="description"
              name="description"
              value={request.description}
              onChange={handleChange}
            />
          </div>
          
          <div>
            <label htmlFor="isCompleted">Tamamlandı mı?</label>
            <select
              id="isCompleted"
              name="isCompleted"
              value={request.isCompleted}
              onChange={handleChange}
            >
              <option value={false}>Hayır</option>
              <option value={true}>Evet</option>
            </select>
          </div>
          <button type="submit">Güncelle</button>
        </form>
      )}
    </div>
  );
};

export default EditRequestPage;
